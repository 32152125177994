import React from "react"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout meta={{ title: "Page not found" }}>
    <section className="lg-pad">
      <div className="container">
        <div className="row">
          <h1>Page not found</h1>
          <p>Sorry that might be us, not you.</p>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
